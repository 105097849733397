import React from 'react';
import { Box, Text } from '@chakra-ui/react';

interface ChatBoxProps {
  from: string;
  text: string;
}

export const ChatBox: React.FC<ChatBoxProps> = ({ from, text }) => {
  const isUser = from === 'You';
  return (
    <Box
      bg={isUser ? 'blue.100' : 'lightgray'}
      p={3}
      borderRadius="md"
      alignSelf={isUser ? 'flex-end' : 'flex-start'}
      maxWidth="80%"
    >
      <Text fontWeight="bold">{from}</Text>
      <Text>{text}</Text>
    </Box>
  );
};
