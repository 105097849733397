import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  VStack,
  Button,
  Textarea,
  Container,
  Alert,
  AlertIcon,
  Link,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { ChatBox } from "../components/ChatBox";
import {
  startConversation,
  sendMessage,
  sendAudioMessage,
} from "../services/api";
import { useNavigationBlocker } from "../hooks/useNavigationBlocker";

const ChatPage: React.FC = () => {
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [messages, setMessages] = useState<{ from: string; text: string }[]>(
    []
  );
  const [input, setInput] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<BlobPart[]>([]);

  useEffect(() => {
    const initiateChat = async () => {
      try {
        const response = await startConversation();
        setConversationId(response.conversationId);
        setMessages([
          {
            from: "System",
            text: "Welcome, How may I help you today?",
          },
        ]);
        setError("");
      } catch (err) {
        setError("An error occurred while starting the chat.");
      }
    };

    initiateChat();
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleStartChat = async () => {
    try {
      const response = await startConversation();
      setConversationId(response.conversationId);
      setMessages([
        {
          from: "System",
          text: "Welcome, How may I help you today?",
        },
      ]);
      setError("");
      onClose();
    } catch (err) {
      setError("An error occurred while starting the chat.");
    }
  };

  const handleSendMessage = async () => {
    if (!conversationId) {
      setError("Please start a chat first.");
      return;
    }

    if (input.trim() === "") {
      setError("Please enter a message to send.");
      return;
    }

    try {
      const userMessage = { from: "You", text: input };
      setMessages((prev) => [...prev, userMessage]);

      const response = await sendMessage(conversationId, input);
      const systemMessage = { from: "System", text: response.message };
      setMessages((prev) => [...prev, systemMessage]);

      setInput("");
      setError("");
    } catch (err) {
      setError("An error occurred while sending the message.");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const confirmStartChat = () => {
    if (conversationId && messages.length > 0) {
      onOpen();
    } else {
      handleStartChat();
    }
  };

  useNavigationBlocker(
    "Are you sure you want to leave? Your chat conversation will be lost.",
    messages.length > 0
  );

  const handleSendAudioMessage = async (audioBuffer: ArrayBuffer) => {
    if (conversationId) {
      const response = await sendAudioMessage(conversationId, audioBuffer);
      const systemMessage = { from: "System🔊", text: response.message };
      setMessages((prev) => [...prev, systemMessage]);
    }
  };

  const handleRecordAudio = () => {
    if (!isRecording) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          const mediaRecorder = new MediaRecorder(stream, {
            mimeType: "audio/webm",
          });
          mediaRecorderRef.current = mediaRecorder;
          audioChunksRef.current = [];
          mediaRecorder.start();

          mediaRecorder.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
          };

          mediaRecorder.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, {
              type: "audio/webm",
            });
            const reader = new FileReader();
            reader.readAsArrayBuffer(audioBlob);
            reader.onloadend = () => {
              if (reader.result) {
                handleSendAudioMessage(reader.result as ArrayBuffer);
              }
            };
          };

          setIsRecording(true);
        });
      }
    } else {
      mediaRecorderRef.current?.stop();
      setIsRecording(false);
    }
  };

  return (
    <Flex direction="column" height="100vh">
      <Container maxW="container.md" p={4} flex="1" overflowY="auto">
        <Link as={RouterLink} to="/" color="teal.500" mb={4} display="block">
          &larr; Back to Home
        </Link>
        <Button onClick={confirmStartChat} colorScheme="teal" mb={4}>
          Start Chat
        </Button>
        <VStack
          spacing={4}
          align="stretch"
          overflow={"scroll"}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}>
          {messages.map((msg, index) => (
            <ChatBox key={index} from={msg.from} text={msg.text} />
          ))}
          <div ref={messagesEndRef} />
        </VStack>
      </Container>
      <Box
        p={4}
        borderTop="1px solid #e2e8f0"
        position="fixed"
        bottom="0"
        left="0"
        width="100%"
        bg="white">
        <Container maxW="container.md">
          <Textarea
            placeholder="Type your message here..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            mb={2}
            onKeyDown={handleKeyDown}
          />
          <Button onClick={handleSendMessage} colorScheme="teal" width="100%">
            Send
          </Button>
          <Button onClick={handleRecordAudio} colorScheme="teal" mt={2}>
            {isRecording ? "Stop Recording" : "Record Audio"}
          </Button>
          {error && (
            <Alert status="error" mt={4}>
              <AlertIcon />
              {error}
            </Alert>
          )}
        </Container>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Start New Chat</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to start a new chat? This will clear the
              existing conversation.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleStartChat}>
              Yes
            </Button>
            <Button variant="ghost" onClick={onClose}>
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ChatPage;
