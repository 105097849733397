import axios from 'axios';

// const API_URL = 'http://localhost:3001';
const API_URL = 'https://api.spacetimebots.com';

let authToken = '';

const setAuthToken = (token: string) => {
  authToken = token;
};

const getAuthToken = () => {
  return authToken;
};

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = getAuthToken();
  if (token) {
    config.headers['Authorization'] = `Basic ${token}`;
  }
  return config;
});

export const startConversation = async () => {
  const response = await axiosInstance.post('/chat/start');
  return response.data;
};

export const sendMessage = async (conversationId: string, text: string) => {
  const response = await axiosInstance.post('/chat/message', {
    conversationId,
    text,
  });
  return response.data;
};

export const uploadFile = async (file: File, contentType: string, fileName: string) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('contentType', contentType);
  formData.append('fileName', fileName);

  const response = await axiosInstance.post('/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const sendAudioMessage = async (conversationId: string, audioBuffer: ArrayBuffer) => {
  const formData = new FormData();
  const blob = new Blob([audioBuffer], { type: 'audio/wav' });
  formData.append('audio', blob);
  formData.append('conversationId', conversationId);

  const response = await axiosInstance.post('/chat/audio-message', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const login = async (username: string, password: string) => {
  const response = await axiosInstance.post('/auth/login', { username, password });
  console.log("response", response);
  if (response.data.access_token) {
    setAuthToken(response.data.access_token);
    return true;
  }
  return false;
};

export const getActivityLogs = async () => {
  const response = await axiosInstance.get('/front-desk/get-logs');
  return response.data;
};

export const getPersons = async () => {
  const response = await axiosInstance.get('/front-desk/persons');
  return response.data;
};

export const addPerson = async (personData: { name: string; status: string }) => {
  const response = await axiosInstance.post('/front-desk/persons', personData);
  return response.data;
};

export const updatePerson = async (id: number, personData: { name: string; status: string }) => {
  const response = await axiosInstance.put(`/front-desk/persons/${id}`, personData);
  return response.data;
};

export const getPersonActivityLogs = async (personId: number) => {
  const response = await axiosInstance.get(`/front-desk/person-activity-logs/${personId}`);
  return response.data;
};

export { setAuthToken, getAuthToken };
