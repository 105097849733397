import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useNavigationBlocker = (message: string, when: boolean) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!when) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = message;
    };

    const handleBlockNavigation = (event: PopStateEvent) => {
      if (window.confirm(message)) {
        // Allow navigation
      } else {
        // Prevent navigation
        event.stopImmediatePropagation();
        navigate(1); // Go forward again
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handleBlockNavigation);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleBlockNavigation);
    };
  }, [when, message, navigate]);
};
