import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Select, VStack, Textarea, Container, Text, Alert, AlertIcon, Link, Input } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { uploadFile } from '../services/api';

const UploadPage: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [contentType, setContentType] = useState<string>('Select');
  const [fileName, setFileName] = useState<string>('fileName');
  const [response, setResponse] = useState<string>('');
  const [error, setError] = useState<string>('');

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
    setError(''); // Clear error when a new file is dropped
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = async () => {
    if (file) {
      try {
        const response = await uploadFile(file, contentType, fileName);
        setResponse(response.message);
        setError('');
      } catch (err) {
        setError('An error occurred while uploading the file.');
      }
    } else {
      setError('Please select a file to upload.');
    }
  };

  return (
    <Container maxW="container.md" p={4}>
      <Link as={RouterLink} to="/" color="teal.500" mb={4} display="block">
        &larr; Back to Home
      </Link>
      <VStack spacing={4} align="stretch">
        <Box
          {...getRootProps()}
          p={5}
          border="2px dashed"
          borderColor={isDragActive ? 'teal.400' : 'gray.200'}
          borderRadius="md"
          textAlign="center"
          height="400px"
          display="flex"
          alignItems="center"
          justifyContent="center" 
          backgroundColor={isDragActive ? 'teal.50' : 'gray.50'}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Text>Drop the files here...</Text>
          ) : (
            <Text>Drag 'n' drop some files here, or click to select files</Text>
          )}
        </Box>
        {file && (
          <Box p={4} borderWidth="1px" borderRadius="md">
            <Text><strong>File Name:</strong> {file.name}</Text>
            <Text><strong>File Size:</strong> {(file.size / 1024).toFixed(2)} KB</Text>
            <Text><strong>File Type:</strong> {file.type}</Text>
          </Box>
        )}
        <Select border="2px" value={contentType} onChange={(e) => setContentType(e.target.value)}>
          <option value="Select">Select Type of content</option>
          <option value="Facts">Facts</option>
          <option value="Official">Official</option>
          <option value="Published">Published</option>
          <option value="Opinions">Opinions</option>
        </Select>
        {file && (<Input border="2px" placeholder='Enter filename' defaultValue={file.name} onChange={(e) => setFileName(e.target.value)} >
        </Input>) }     
        <Button onClick={handleUpload} colorScheme="teal" isDisabled={contentType === 'Select'}>
          Upload
        </Button>
        {error && (
          <Alert status="error" mt={4}>
            <AlertIcon />
            {error}
          </Alert>
        )}
        {response && <Textarea value={response} isReadOnly />}
      </VStack>
    </Container>
  );
};

export default UploadPage;
