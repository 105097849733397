import React, { useState, useEffect } from 'react';
import { Box, Container, Heading, Table, Thead, Tbody, Tr, Th, Td, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { getActivityLogs } from '../services/api';

interface ActivityLog {
  id: number;
  time: string;
  action: string;
  visitor: string;
  resident: string;
}

const ActivityLogPage: React.FC = () => {
  const [activityLogs, setActivityLogs] = useState<ActivityLog[]>([]);

  useEffect(() => {
    const fetchActivityLogs = async () => {
      try {
        const logs = await getActivityLogs();
        setActivityLogs(logs);
      } catch (error) {
        console.error('Failed to fetch activity logs:', error);
      }
    };

    fetchActivityLogs();
  }, []);

  return (
    <Container maxW="container.xl" p={4}>
      <Link as={RouterLink} to="/" color="teal.500" mb={4} display="block">
        &larr; Back to Home
      </Link>
      <Heading as="h1" size="xl" mb={6}>
        Activity Log
      </Heading>
      <Box overflowX="auto">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Time</Th>
              <Th>Action</Th>
              <Th>Visitor</Th>
              <Th>Resident</Th>
            </Tr>
          </Thead>
          <Tbody>
            {activityLogs.map((log) => (
              <Tr key={log.id}>
                <Td>{new Date(log.time).toLocaleString()}</Td>
                <Td>{log.action}</Td>
                <Td>{log.visitor}</Td>
                <Td>{log.resident}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Container>
  );
};

export default ActivityLogPage;
