import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Heading,
  Button,
  VStack,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";

interface HomePageProps {
  isAuthenticated: boolean;
  onLogout: () => void;
}

const HomePage: React.FC<HomePageProps> = ({ isAuthenticated, onLogout }) => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h1" size="2xl" mb={6}>
        Space Time Bots Admin Dashboard
      </Heading>
      {isAuthenticated ? (
        <VStack spacing={6}>
          <Text fontSize="xl">Welcome to the Admin Dashboard</Text>
          <SimpleGrid
            columns={[1, null, 2]}
            spacing={4}
            width={["100%", "100%", "50%"]}
            maxWidth="1024px">
            <Button
              as={Link}
              to="/person-management"
              colorScheme="purple"
              size="lg"
              width="100%">
              Manage Persons
            </Button>
            <Button
              as={Link}
              to="/activity-log"
              colorScheme="green"
              size="lg"
              width="100%">
              View All Activity
            </Button>
            <Button
              as={Link}
              to="/chat"
              colorScheme="teal"
              size="lg"
              width="100%">
              Chat with Bot
            </Button>
            <Button
              as={Link}
              to="/upload"
              colorScheme="blue"
              size="lg"
              width="100%">
              Upload Content
            </Button>
          </SimpleGrid>
          <Button onClick={onLogout} colorScheme="red" size="md">
            Logout
          </Button>
        </VStack>
      ) : (
        <Text fontSize="lg">
          Please{" "}
          <Link
            to="/login"
            style={{ color: "teal", textDecoration: "underline" }}>
            login
          </Link>{" "}
          to access admin features.
        </Text>
      )}
    </Box>
  );
};

export default HomePage;
