import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure,
  Link,
  Text,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { getPersons, addPerson, updatePerson, getPersonActivityLogs } from '../services/api';

interface Person {
  id: number;
  name: string;
  namelc: string;
  status: string;
}

interface ActivityLog {
  id: number;
  time: string;
  action: string;
  visitor: string;
  resident: string;
}

const PersonManagementPage: React.FC = () => {
  const [persons, setPersons] = useState<Person[]>([]);
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
  const [activityLogs, setActivityLogs] = useState<ActivityLog[]>([]);
  const { isOpen: isPersonModalOpen, onOpen: onPersonModalOpen, onClose: onPersonModalClose } = useDisclosure();
  const { isOpen: isActivityModalOpen, onOpen: onActivityModalOpen, onClose: onActivityModalClose } = useDisclosure();
  const [formData, setFormData] = useState({ name: '', status: 'in' });

  useEffect(() => {
    fetchPersons();
  }, []);

  const fetchPersons = async () => {
    try {
      const fetchedPersons = await getPersons();
      setPersons(fetchedPersons);
    } catch (error) {
      console.error('Failed to fetch persons:', error);
    }
  };

  const handleOpenPersonModal = (person: Person | null) => {
    setSelectedPerson(person);
    setFormData(person ? { name: person.name, status: person.status } : { name: '', status: 'in' });
    onPersonModalOpen();
  };

  const handleOpenActivityModal = async (person: Person) => {
    try {
      const logs = await getPersonActivityLogs(person.id);
      setActivityLogs(logs);
      setSelectedPerson(person);
      onActivityModalOpen();
    } catch (error) {
      console.error('Failed to fetch activity logs:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      const personData = {
        ...formData,
        namelc: formData.name.toLowerCase(),
      };
      if (selectedPerson) {
        await updatePerson(selectedPerson.id, personData);
      } else {
        await addPerson(personData);
      }
      fetchPersons();
      onPersonModalClose();
    } catch (error) {
      console.error('Failed to save person:', error);
    }
  };

  return (
    <Container maxW="container.xl" p={4}>
      <Link as={RouterLink} to="/" color="teal.500" mb={4} display="block">
        &larr; Back to Home
      </Link>
      <Heading as="h1" size="xl" mb={6}>
        Person Management
      </Heading>
      <Button onClick={() => handleOpenPersonModal(null)} colorScheme="teal" mb={4}>
        Add New Person
      </Button>
      <Box overflowX="auto">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {persons.map((person) => (
              <Tr key={person.id}>
                <Td>{person.name}</Td>
                <Td>{person.status}</Td>
                <Td>
                  <Button onClick={() => handleOpenPersonModal(person)} size="sm" mr={2}>
                    Edit
                  </Button>
                  <Button onClick={() => handleOpenActivityModal(person)} size="sm" colorScheme="blue">
                    Activity Log
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Modal isOpen={isPersonModalOpen} onClose={onPersonModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedPerson ? 'Edit Person' : 'Add New Person'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Status</FormLabel>
              <Select
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value as 'in' | 'out' | 'blocked' })}
              >
                <option value="in">In</option>
                <option value="out">Out</option>
                <option value="blocked">Blocked</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Save
            </Button>
            <Button onClick={onPersonModalClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isActivityModalOpen} onClose={onActivityModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Activity Log for {selectedPerson?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Time</Th>
                  <Th>Action</Th>
                  <Th>Visitor</Th>
                </Tr>
              </Thead>
              <Tbody>
                {activityLogs.map((log) => (
                  <Tr key={log.id}>
                    <Td>{new Date(log.time).toLocaleString()}</Td>
                    <Td>{log.action}</Td>
                    <Td>{log.visitor}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {activityLogs.length === 0 && (
              <Text>No activity logs found for this person.</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onActivityModalClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default PersonManagementPage;
